
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.topSection {
    height: auto;
    min-height: calc(100vh - 58px);
    background-color: #f4f4f4;
    position: relative;
    @media screen and (max-width: 600px) {
        padding-bottom: 170px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: calc(100vh - 58px);
        position: absolute;
        background-image: url("/assets/images/desktop2Opt.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left center;
        z-index: 0;
        @media screen and (max-width: 1620px) {
            background-position: -100px center;
        }
        @media screen and (max-width: 1380px) {
            background-position: -150px center;
        }
        @media screen and (max-width: 1366px) {
            background-size: 1770px;
            background-position: -150px center;
        }
        @media screen and (max-width: 1110px) {
            background-size: 1580px;
        }
        @media screen and (max-width: 767px) {
            background-size: 100%;
            background-image: url("/assets/images/mobile3Opt.jpg");
            background-position: bottom center;
        }
        @media screen and (max-width: 600px) {
            height: 100%;
            min-height: inherit;
        }
    }
}
.mxWrapper {
    width: 100%;
    max-width: 1380px;
    margin: 0 auto;
}
.offerContainer {
    min-height: calc(100vh - 58px);

    position: relative;
}
